.main {
  width: 100%;
}

.description {
  line-height: 1.5;
  font-size: 1.2rem;
  text-align: justify;
  padding-bottom: 25px;
  max-width: 800px;
  margin: 0 auto;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.card {
  margin: 1rem;
  flex-basis: 45%;
  padding: 2.5rem;
  text-align: left;
  text-decoration: none;
  border: 1px solid var(--tangential-tan);
  transition: color 0.15s ease, border-color 0.15s ease;
  width: 400px;
}

.card a {
  width: 100%;
  display: inline-block;
  margin: 10px auto 5px auto;
  padding: 10px 15px;
  border: 1px solid var(--tangential-tan);
  text-align: center;
  text-transform: uppercase;
}

.card a:hover {
  background: var(--meaningful-maroon);
}

.grid .card:nth-child(odd) {
  background: var(--cynical-cyan);
  color: var(--tangential-tan);
}

.grid .card:nth-child(even) {
  color: var(--tangential-tan);
  background: var(--grateful-green);
  border: var(--blissful-blue);
}

.card h3 {
  font-family: 'BlackCoffee';
  margin: 0 0 1rem 0;
  font-size: 2.0rem;
  text-align: center;
}

.card p {
  margin: 0 0 15px 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
}

.back {
  margin: 20px auto;
  width: 200px;
  border-radius: 7px;
  border: 1px solid #eaeaea;
  text-align: center;
}

.back:hover {
  color: #0070f3;
  border-color: #0070f3;
}

.question {
  text-align: center;
  margin-bottom: 50px;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .card {
    width: 100%;
    margin: 0;
  }
  .main {
    width: 100%;
    max-width: 100%;
  }
  
  .description {
    margin: 0 auto;
  }
}
