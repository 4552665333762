.form {
  margin: 0 auto;
  text-align: center;
  position: relative;
  width: 100%;
}

.form iframe {
  max-height: 100vh;
  width: 100%;
  border-radius: 7px;
}

.description {
  color: var(--tangential-tan);
  text-align: justify;
  max-width: 600px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 700px) {
  .form iframe {
    width: 100%;
  }  
}