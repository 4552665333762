:root {
  --meaningful-maroon: #891336;
  --grateful-green: rgb(127, 163, 92);
  --tangential-tan: #f7f2db;
  --cynical-cyan: #45a8a0;
  --blissful-blue: #8edcc7;
  --goldy-gold: #f9a209;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "DINBody";

  color: #444;
  width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1 {
  font-family: "BlackCoffee";
}

header {
  position: relative;
  /* top: 0;
  left: 0;
  right: 0; */
  width: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  background: var(--tangential-tan);
}

header .header-content {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

header ul {
  margin: 0;
}

header li {
  list-style: none;
  display: inline-block;
  font-size: 1.2rem;
  border-right: 1px solid #000;
  text-align: center;
  padding: 0 50px;
  line-height: 40px;
  text-transform: uppercase;
  font-weight: bold;
  color: #444;
  font-size: 24px;
}

header li a:hover {
  text-decoration: underline;
}

header li:last-child {
  border-right: none;
}

main {
  background: var(--meaningful-maroon);
  color: var(--tangential-tan);
  padding: 20px;
  text-align: center;
  /* margin: 0 auto; */
  width: 100%;
}

.container {
  background: var(--tangential-tan);
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
  padding-bottom: 25px;
}

.title,
.description {
  text-align: center;
}

.description {
  max-width: 800px;
}

.loadingContainer {
  position: fixed;
  top: 50px;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid var(--meaningful-maroon);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

@media (max-width: 700px) {
  header .header-content {
    display: block;
  }
  header .logo {
    display: block;
    margin: 0 auto;
  }

  header ul {
    padding: 5px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #6c8fa3;
    text-align: center;
    z-index: 9999;
  }

  header li {
    color: #fff;
    font-size: 16px;
    padding: 0 10px;
    border: none;
  }

  .container {
    padding: 0;
    display: block;
    width: 100%;
  }

  .title {
    font-size: 2rem;
  }

  h3 {
    font-size: 1rem;
  }
}
